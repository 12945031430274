<template>
  <svg height="16" width="16" :class="[{ active: active }, color, 'dot']">
    <circle cx="8" cy="8" r="8" stroke="none" fill="var(--mid-red)" />
  </svg>
</template>
<script>
export default {
  name: "status-button",
  props: {
    color: {
      type: String,
      default: "red",
    },
    active: {
    },
  },
};
</script>

<style>
.dot {
  cursor: pointer;
  opacity: 0.2;
}

.dot:not(:first-of-type) {
  margin-left: 8px;
}

.dot.active {
  opacity: 1;
}

.dot.red > circle {
  fill: var(--mid-red);
}

.dot.orange > circle {
  fill: var(--mid-orange);
}

.dot.green > circle {
  fill: var(--mid-green);
}
</style>