<template>
  <div class="content">
    <h2>Nastavení</h2>
    <h3>Řazení stránek</h3>
    <draggable
      v-model="pages"
      @start="onDragStart"
      @end="onDragEnd"
      filter=".ignore-draggable"
      handle=".menu-icon"
      :forceFallback="true"
      :preventOnFilter="false"
    >
      <transition-group tag="div" name="flip-list">
        <div
          v-for="page in pages"
          :key="page.slug"
          class="admin__order-content"
        >
          <div class="menu-icon">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
          {{ page.name }}
          <div class="ignore-draggable admin__content-bar">
            <status-button
              :active="+page.published"
              color="green"
              @click.native="(page.published = '1'), publishing(page.slug, '1')"
            ></status-button>
            <status-button
              :active="!+page.published"
              color="red"
              @click.native="(page.published = '0'), publishing(page.slug, '0')"
            ></status-button>
            <icon
              name="delete"
              class="admin__content-bar-button"
              @click.native="deleteContent(page.slug)"
            />
          </div>
        </div>
      </transition-group>
    </draggable>
    <button class="btn btn-red" @click="reorder">Uložit řazení</button
    ><Loader
      class="loader--inline"
      :show="reorderIcon.show"
      :type="reorderIcon.type"
    ></Loader>
    <h3>Kalendáře</h3>
    <p>
      Přidej nebo smaž řádky s kalendáři, které chceš zobrazit na stránce
      <router-link to="kalendar">Kalendář</router-link>. Kalendář musí být ve
      formátu ical (.ics).
    </p>
    <input
      type="text"
      v-for="(calendar, index) in calendars"
      :key="index"
      class="--full-width"
      v-model="calendar.calendar"
      @input="addField"
      @change="calendarIcon.show = false"
    />
    <button class="btn btn-red" @click="updateCals">
      Aktualizovat kalendáře</button
    ><Loader
      class="loader--inline"
      :show="calendarIcon.show"
      :type="calendarIcon.type"
    ></Loader>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import StatusButton from "@/components/StatusButton";
import Icon from "@/components/Icon";

export default {
  name: "Admin",
  components: {
    draggable,
    StatusButton,
    Icon,
  },
  data() {
    return {
      grabbing: false,
      calendars: [],
      reorderIcon: {
        show: false,
        type: "success",
      },
      calendarIcon: {
        show: false,
        type: "success",
      },
      pages: [],
    };
  },
  created() {
    this.getCals();
    this.getUnpublished();
  },
  methods: {
    onDragStart: function () {
      const className = "grabbing";
      const html = document.getElementsByTagName("html").item(0);
      if (html && new RegExp(className).test(html.className) === false) {
        html.className += " " + className; // use a space in case there are other classNames
      }
    },
    onDragEnd: function () {
      this.reorderIcon.show = false;
      const className = "grabbing";
      const html = document.getElementsByTagName("html").item(0);
      if (html && new RegExp(className).test(html.className) === true) {
        // Remove className with the added space (from setClassToHTMLElement)
        html.className = html.className.replace(
          new RegExp(" " + className),
          ""
        );
        // Remove className without added space (just in case)
        html.className = html.className.replace(new RegExp(className), "");
      }
    },
    updateStore: function () {
      this.$store.state.content = this.pages.filter((p) => {
        return p.published > 0;
      });
    },
    reorder: function () {
      this.reorderIcon.show = false;
      axios
        .patch("/content.php", {
          content: this.pages,
        })
        .then((res) => {
          if (res.data.success) {
            this.reorderIcon.show = true;
          }
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
    publishing: function (slug, published) {
      axios
        .patch("/content.php", {
          publishing: true,
          slug: slug,
          published: published,
        })
        .then((res) => {
          if(!res.data.success) {
            console.log("Došlo k chybě při odstranění stránky, kontaktujte administrátora webu.");
          }
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
    getUnpublished: function () {
      axios
        .get("/content.php?unpublished=true")
        .then((res) => {
          this.pages = res.data.sort((a, b) => {
            return a.order_by - b.order_by;
          });
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
    getCals: function () {
      axios
        .get("/events.php?urls=true")
        .then((res) => {
          this.calendars = res.data;
          this.addField();
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
    updateCals: function () {
      this.calendarIcon.show = false;
      axios
        .post("/events.php", {
          data: this.calendars,
        })
        .then(() => {
          this.calendarIcon.show = true;
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
    addField: function () {
      this.calendars = this.calendars.filter((cal) => {
        return cal.calendar !== "";
      });
      this.calendars.push({ calendar: "" });
    },
    forceLogin: function (e) {
      if (e.response.status == 403) {
        this.$root.$children[0].showLogin = true;
      }
    },
    deleteContent: function (slug) {
      var confirmDelete = confirm(
        "Skutečně chcete natrvalo smazat tuto stránku?"
      );
      if (!confirmDelete) {
        return;
      }
      axios
        .delete("/content.php", { data: { slug: slug } })
        .then(() => {
          this.pages = this.pages.filter((el) => el.slug !== slug);
          this.$parent.navKey++;
        })
        .catch((e) => {
          this.forceLogin(e);
        });
    },
  },
  watch: {
    pages: {
      deep: true,
      handler: function () {
        this.updateStore();
      },
    },
  },
};
</script>

<style>
.admin__order-content {
  padding: 16px 8px;
  margin-bottom: 8px;
  width: 100%;
  background: var(--mid-dark-gray);
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}

.admin__order-content .menu-icon {
  cursor: grab;
}

.admin__content-bar {
  justify-self: flex-end;
  margin-left: auto;
  margin-right: 8px;
  height: 16px;
  margin-top: 4px;
}

.admin__content-bar-button {
  cursor: pointer;
  margin-left: 8px;
  border: 0;
  padding: 0;
  display: inline-block;
}

.admin__content-bar-button.icon > svg {
  filter: invert(0.5);
  transition: filter 0.16s ease-in-out;
}

.admin__content-bar-button.icon:hover > svg {
  filter: invert(0.2);
}

.grabbing * {
  cursor: grabbing !important;
}

.flip-list-enter {
  animation: flip-list 0.5s;
}

@keyframes flip-list {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.menu-icon {
  display: inline-block;
  width: 16px;
  margin-right: 8px;
}

.menu-icon > .line {
  background-color: var(--mid-gray);
  height: 2px;
  display: block;
  margin-top: 4px;
}
</style>
